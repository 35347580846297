<template>
  <div>
     <validation-observer ref="simpleRules">
      <b-form ref="password_reset_form" action="javascript:void(0)" @submit="resetPassword()">
        
        <b-row>
          <b-col cols="12" md="4">
            <b-form-group
                label="User Role"
                label-for="role_id" >
              <select name="role_id" v-model.number="role_id" class="form-control"  @change="showUserList();"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'">
                <option :value="null">Select One</option>
                <option v-for="(role,key) in roles" :value="role.id" :key="key">{{ role.name }}</option>
              </select>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" md="4">
            <b-form-group
                label="User Name"
                label-for="user_id" >
              <select name="user_list" v-model.number="user_id" class="form-control"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'">
                <option :value="null">Select One</option>
                <option v-for="(user, key) in filtered_users" :value="user.id" :key="key">{{(user.role_id == 4)? (user.cadet_no +"-"+ user.name) : user.name}}</option>
              </select>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" md="4">
            <b-form-group>
              <validation-provider
                  #default="{ errors }"
                  name="new password"
                  rules="required" >
                
                <b-card-text>
                  <span>{{ $t("Password") }} </span>
                </b-card-text>
                <b-form-input type="password"
                    v-model="password"
                    name="password"
                    :state="errors.length > 0 ? false:null"
                    placeholder="New password" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" md="4">
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    name="Confirm Password"
                    rules="required"
                >
                <b-card-text>
                  <span>{{ $t("Confirm Password") }} </span>
                </b-card-text>
                  <b-form-input type="password"
                      v-model="password_confirmation"
                      name="password_confirmation"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Confirm Password"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
        </b-row>

        
        <b-button
            variant="primary" type="submit"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'">
          Reset Password
        </b-button>

      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BForm, BFormInput,  BCardText
} from 'bootstrap-vue'

import apiCall from "@/libs/axios";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {mapGetters} from "vuex";

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BCardText,
    ValidationProvider, ValidationObserver
  },
  data(){
    return{
      role_id:null,
      user_id:null,
      old_password:null,
      password:null,
      password_confirmation:null,
      filtered_users: [],
      all_users:[]
    }
  },
  created() {
    this.getAllUsers();
    this.$store.dispatch('GET_ALL_ROLE');
  },

  methods: {
     showUserList() {
      this.filtered_users = [];

      this.filtered_users = this.all_users
          .map((item) => {
            if (parseInt(item.role_id) === this.role_id)
              return item;
          })
          .filter(Boolean);
    },
    resetPassword(){
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data =new FormData(this.$refs.password_reset_form);

          apiCall.post('/selected/user/reset/password', data).then((response)=>{
            if(response.data.status==='success'){
              this.$toaster.success(response.data.message);
              this.password=null;
              this.password_confirmation=null;
              this.$refs.simpleRules.reset();
            }else{
              this.$toaster.error(response.data.message);
            } 

          }).catch((error)=>{
            if (error.response.status == 422) {
              Object.keys(error.response.data.errors).map((field) => {
                this.$toaster.error(error.response.data.errors[field][0]);
              })
            }
            else this.$toaster.error(error.response.data.message);
          });
        }
      })
    },

    async getAllUsers() {
      await apiCall
        .get("/get/current/user/list")
        .then((response) => {
          this.all_users = response.data;
        })
        .catch(() => {
          this.all_users = [];
        });
    },
  },
  computed: {
     roles(){
      return this.$store.getters.roles.filter(item=>item.id !== 1);
    },
    ...mapGetters(['getEmployeeId', 'cadetIdByID']),
  },
  watch:{
    user_list(){
      this.filtered_users = [];
      this.filtered_users = this.all_users
          .map((item) => {
            if (parseInt(item.role_id) === this.role_id)
              return item;
          })
          .filter(Boolean);
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
